<template>
  <mds-modal
    :visible="visible"
    width="600px"
    class="create-user"
    @update:visible="$emit('update:visible', $event)"
  >
    <mds-section
      border-position="bottom"
      bold
    >
      <template #mds-section-title>
        <span>{{ title }}</span>
      </template>
      <mds-row>
        <mds-col>
          <mds-input
            v-model="userName"
            label="Username"
            :error="!!userNameError"
            :error-text="[userNameError]"
            required
          />
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col>
          <mds-input
            v-model="fullName"
            label="Full Name"
            :error="!!fullNameError"
            :error-text="[fullNameError]"
            required
          />
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col>
          <mds-input
            v-model="emailInput"
            label="Email"
            type="text"
            :error="!!emailError"
            :error-text="[emailError]"
            required
          />
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col>
          <mds-combo-box
            v-model="selectedTenantCodes"
            label="Select Tenants"
            multiple
            :data-set="tenants"
            :error="!!tenantsError"
            :error-text="[tenantsError]"
            required
          />
        </mds-col>
      </mds-row>
    </mds-section>
    <mds-row>
      <mds-col>
        <mds-button-container right-aligned>
          <mds-button
            variation="secondary"
            @click="cancel"
          >
            Cancel
          </mds-button>
          <mds-button
            variation="primary"
            @click="handleSubmit"
          >
            Confirm
          </mds-button>
        </mds-button-container>
      </mds-col>
    </mds-row>
  </mds-modal>
</template>

<script>
import MdsModal from '@mds/modal';
import MdsInput from '@mds/input';
import MdsSection from '@mds/section';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsComboBox from '@mds/combo-box';
import { getTenants } from '@/services/api/tenant.service';

export default {
  name: 'UserCreationModal',
  components: {
    MdsModal,
    MdsInput,
    MdsSection,
    MdsButton,
    MdsButtonContainer,
    MdsComboBox,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Create User',
    },
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      userName: this.initialData.userName || '',
      fullName: this.initialData.fullName || '',
      emailInput: this.initialData.email || '',
      selectedTenantCodes: [],
      tenants: [],
      userNameError: '',
      fullNameError: '',
      emailError: '',
      tenantsError: '',
    };
  },
  computed: {
    email () {
      const domain = '@morningstar.com';
      return this.emailInput.includes(domain) ? this.emailInput : this.emailInput + domain;
    },
    selectedTenants () {
      return this.tenants
        .filter((tenant) => this.selectedTenantCodes.includes(tenant.code))
        .map((tenant) => ({
          id: tenant.id,
          name: tenant.name,
          code: tenant.code,
        }));
    },
  },
  watch: {
    userName (newVal) {
      if (newVal) {
        this.userNameError = '';
      }
    },
    fullName (newVal) {
      if (newVal) {
        this.fullNameError = '';
      }
    },
    emailInput (newVal) {
      if (newVal) {
        this.emailError = '';
      }
    },
    selectedTenantCodes (newVal) {
      if (newVal.length > 0) {
        this.tenantsError = '';
      }
    },
  },
  async mounted () {
    await this.fetchTenants();
    if (this.initialData.tenants) {
      this.selectedTenantCodes = this.initialData.tenants.map((tenant) => tenant.code);
    }
  },
  methods: {
    async fetchTenants () {
      const { data } = await getTenants();
      this.tenants = data.map((tenant) => ({
        id: tenant.id,
        name: tenant.name,
        code: tenant.code,
        text: tenant.name,
        value: tenant.code,
      }));
    },
    validateForm () {
      this.userNameError = this.userName ? '' : 'Username is required';
      this.fullNameError = this.fullName ? '' : 'Full Name is required';
      this.emailError = this.validateEmail() ? '' : 'Valid email is required';
      this.tenantsError = this.selectedTenantCodes.length > 0 ? '' : 'At least one tenant is required';

      return !this.userNameError && !this.fullNameError && !this.emailError && !this.tenantsError;
    },
    validateEmail () {
      if (!this.emailInput) {
        return false;
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.email);
    },
    handleSubmit () {
      if (this.validateForm()) {
        const newUser = {
          userName: this.userName,
          fullName: this.fullName,
          email: this.email,
          tenants: this.selectedTenants,
        };
        this.$emit('confirm', newUser);
        this.cancel();
      }
    },
    cancel () {
      this.$emit('update:visible', false);
      this.resetForm();
    },
    resetForm () {
      this.userName = '';
      this.fullName = '';
      this.emailInput = '';
      this.selectedTenantCodes = [];
      this.userNameError = '';
      this.fullNameError = '';
      this.emailError = '';
      this.tenantsError = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.create-user {
  ::v-deep .mds-modal__wrapper__dap-crawler .mds-modal__content__dap-crawler {
    overflow: inherit
  }
}
</style>
