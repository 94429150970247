<template>
  <div>
    <div v-if="isCreateModalVisible">
      <user-tenant-form-modal
        :visible="isCreateModalVisible"
        title="Link Tenant"
        :username="modalUsername"
        @update:visible="isCreateModalVisible = $event"
        @confirm="addTenantToUser"
      />
    </div>
    <div v-if="isEditModalVisible">
      <user-creation-modal
        :visible="isEditModalVisible"
        :title="'Edit User'"
        :initial-data="editingUser"
        @update:visible="isEditModalVisible = $event"
        @confirm="updateUser"
      />
    </div>
    <confirmation-modal
      :visible="isModalVisible"
      :title="modalTitle"
      :text="modalText"
      @update:visible="isModalVisible = $event"
      @confirm="removeTenant"
    />
    <confirmation-modal
      :visible="isDeleteModalVisible"
      title="Delete User"
      text="Are you sure you want to delete this user?"
      @update:visible="isDeleteModalVisible = $event"
      @confirm="deleteUser"
    />
    <mds-table row-hover>
      <mds-thead>
        <mds-th
          v-for="header in $options.headers"
          :key="header.fieldName"
          :style="header.style"
        >
          {{ header.text }}
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <template v-for="user in users">
          <mds-tr :key="user.id">
            <mds-td
              v-for="header in $options.headers"
              :key="header.fieldName"
              :style="header.style"
            >
              <template v-if="header.fieldName === 'tenants'">
                <template v-for="tenant in user.tenants">
                  <mds-tag
                    :key="tenant.id"
                    class="users__tenant_tag"
                    removable
                    @mds-tag-removed="handleTagRemoved(user.id, tenant.id)"
                  >
                    {{ tenant.name }}
                  </mds-tag>
                </template>
              </template>
              <template v-else-if="header.fieldName === 'actions'">
                <div>
                  <mds-button
                    variation="flat"
                    icon="plus"
                    text="Link Tenant"
                    type="button"
                    @click.stop="toggleCreateModal(user)"
                  />
                  <mds-button
                    variation="icon-only"
                    icon="pencil"
                    type="button"
                    @click.stop="toggleEditModal(user)"
                  />
                  <mds-button
                    variation="icon-only"
                    icon="trash"
                    type="button"
                    @click.stop="toggleDeleteModal(user.id)"
                  />
                </div>
              </template>
              <template v-else>
                {{ user[header.fieldName] || '—' }}
              </template>
            </mds-td>
          </mds-tr>
        </template>
      </mds-tbody>
    </mds-table>
  </div>
</template>

<script>
import Vue from 'vue';
import { formatDate, textFormatter } from '@/utils/global.util';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { MdsTag } from '@mds/tag';
import { MdsButton } from '@mds/button';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import UserTenantFormModal from '@/components/user-management/list/table/UserTenantFormModal';
import UserCreationModal from '@/components/user-management/form/UserCreationModal';
import { TABLE_HEADERS } from '@/constants/users.constant';
import { MODULE } from '@/constants/store.constant';

export default {
  name: 'UsersTable',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsTag,
    MdsButton,
    ConfirmationModal,
    UserTenantFormModal,
    UserCreationModal,
  },
  filters: {
    formatDate,
    textFormatter,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      isModalVisible: false,
      isCreateModalVisible: false,
      isEditModalVisible: false,
      isDeleteModalVisible: false,
      userId: null,
      tenantId: null,
      editingUser: null,
      modalTitle: 'Confirmation',
      modalText: 'Are you sure you want to unlink the tenant from the user?',
    };
  },
  methods: {
    handleTagRemoved (userId, tenantId) {
      this.userId = userId;
      this.tenantId = tenantId;
      this.toggleModal();
    },
    removeTenant () {
      this.$store.dispatch(`${MODULE.USER_MANAGEMENT}/removeTenantFromUser`, { userId: this.userId, tenantId: this.tenantId });
      this.toggleModal();
      Vue.notify({
        group: 'api-level',
        title: 'Success',
        text: 'Tenant successfully unlinked',
        type: 'success',
      });
    },
    addTenantToUser (tenantId) {
      this.$store.dispatch(`${MODULE.USER_MANAGEMENT}/addTenantToUser`, { userId: this.userId, tenantId });
      this.toggleCreateModal();
      Vue.notify({
        group: 'api-level',
        title: 'Success',
        text: 'Tenant successfully linked',
        type: 'success',
      });
    },
    toggleModal () {
      this.isModalVisible = !this.isModalVisible;
    },
    toggleCreateModal (user = null) {
      this.isCreateModalVisible = !this.isCreateModalVisible;
      if (user) {
        this.userId = user.id;
        this.modalUsername = user.fullName || user.userName;
      }
    },
    toggleEditModal (user) {
      this.userId = user.id;
      this.editingUser = { ...user };
      this.isEditModalVisible = !this.isEditModalVisible;
    },
    toggleDeleteModal (userId) {
      this.userId = userId;
      this.isDeleteModalVisible = !this.isDeleteModalVisible;
    },
    updateUser (userData) {
      this.$store.dispatch(`${MODULE.USER_MANAGEMENT}/updateUser`, { ...userData, id: this.userId });
      this.isEditModalVisible = false;
    },
    deleteUser () {
      this.$store.dispatch(`${MODULE.USER_MANAGEMENT}/deleteUser`, { id: this.userId });
      this.isDeleteModalVisible = false;
    },
  },
  headers: TABLE_HEADERS,
};
</script>

<style lang="scss" scoped>
.users {
  &__tenant {
    &_tag {
      margin-right: $mds-space-1-and-a-half-x;
    }
  }
}
</style>
