<template>
  <portal
    v-if="visible"
    to="modals"
  >
    <mds-modal
      v-model="visible"
      width="600px"
      action-required
    >
      <mds-section
        border-position="bottom"
        bold
      >
        <template #mds-section-title>
          <span id="title-id">{{ title }}</span>
        </template>
        <template #mds-section-actions>
          <mds-button-container right-aligned>
            <mds-button
              variation="secondary"
              @click="cancel"
            >
              Cancel
            </mds-button>
            <mds-button
              variation="primary"
              @click="confirm"
            >
              Confirm
            </mds-button>
          </mds-button-container>
        </template>
        {{ text }}
      </mds-section>
    </mds-modal>
  </portal>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';

export default {
  name: 'ConfirmationModal',
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    MdsSection,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: 'Confirmation',
    },
    text: {
      type: String,
      default: 'Are you sure you want to perform this action?',
    },
  },
  methods: {
    cancel () {
      this.$emit('update:visible', false);
    },
    confirm () {
      this.$emit('confirm');
      this.$emit('update:visible', false);
    },
  },
};
</script>
