<template>
  <mds-modal
    v-model="visible"
    width="600px"
  >
    <mds-section
      border-position="bottom"
      bold
    >
      <template #mds-section-title>
        <span id="title-id">{{ title }}</span>
      </template>
      <mds-row>
        <mds-col>
          <mds-input
            v-model="username"
            :label="'User'"
            :disabled="true"
          />
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col>
          <mds-select
            v-model="selectedTenant"
            placeholder="Select an Tenant"
            label="Select Tenant"
            :options="tenants"
            item-value="id"
            item-text="name"
          />
        </mds-col>
      </mds-row>
    </mds-section>
    <mds-row>
      <mds-col>
        <mds-button-container right-aligned>
          <mds-button
            variation="secondary"
            @click="cancel"
          >
            Cancel
          </mds-button>
          <mds-button
            variation="primary"
            :disabled="!selectedTenant"
            @click="confirm"
          >
            Confirm
          </mds-button>
        </mds-button-container>
      </mds-col>
    </mds-row>
  </mds-modal>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import MdsInput from '@mds/input';
import MdsSelect from '@mds/select';
import { getTenants } from '@/services/api/tenant.service';

export default {
  name: 'UserTenantFormModal',
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    MdsSection,
    MdsInput,
    MdsSelect,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: 'Associate Tenant to User',
    },
    username: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      tenants: [],
      selectedTenant: null,
    };
  },
  created () {
    this.getTenants();
  },
  methods: {
    cancel () {
      this.$emit('update:visible', false);
    },
    confirm () {
      this.$emit('confirm', this.selectedTenant);
      this.$emit('update:visible', false);
    },
    async getTenants () {
      const { data: tenants } = await getTenants();
      this.tenants = tenants.map((tenant) => {
        return {
          text: tenant.name,
          value: tenant.code,
        };
      });
    },
  },
};
</script>
