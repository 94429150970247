<template>
  <div>
    <mds-row
      align-horizontal="space-between"
      class="filter"
    >
      <mds-col
        :cols="12"
        :cols-at-s="12"
        :cols-at-m="5"
        :cols-at-l="4"
      >
        <mds-search-field
          v-model="filterText"
          placeholder="Search by Username or Email"
          class="filter__search"
          label="Search"
          @input.native="fetchUsers"
          @mds-search-field-input-cleared="fetchUsersByTerm('')"
        />
      </mds-col>
      <mds-col
        :cols="12"
        :cols-at-s="12"
        :cols-at-m="7"
        :cols-at-l="5"
      >
        <mds-form @submit.native.prevent="getUsers">
          <mds-fieldset
            legend="Filter by Tenants"
            hidden-legend
            horizontal
          >
            <mds-combo-box
              v-model="tenantsSelected"
              label="Filter by Tenant"
              multiple
              class="filter__combo"
              :data-set="tenants"
              @input="setParam($event, 'tenants')"
            />
            <mds-button
              variation="secondary"
              text="Clear"
              class="filter__button"
              @click="clearParams"
            />
            <mds-button
              variation="primary"
              :disabled="tenantsSelected.length==0"
              type="submit"
              text="Search"
              class="filter__button"
            />
          </mds-fieldset>
        </mds-form>
      </mds-col>
    </mds-row>
  </div>
</template>

<script>
import MdsSearchField from '@mds/search-field';
import MdsComboBox from '@mds/combo-box';
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import { MdsButton } from '@mds/button';
import { MODULE, COMMON } from '@/constants/store.constant';
import debounce from 'lodash.debounce';
import { getTenants } from '@/services/api/tenant.service';

export default {
  name: 'FilterSection',
  components: {
    MdsSearchField,
    MdsButton,
    MdsComboBox,
    MdsForm,
    MdsFieldset,
  },
  data () {
    return {
      filterText: '',
      tenants: [],
      tenantsSelected: [],
    };
  },
  created () {
    this.getTenants();
  },
  methods: {
    async getTenants () {
      const { data: tenants } = await getTenants();
      this.tenants = tenants.map((tenant) => {
        return {
          text: tenant.name,
          value: tenant.code,
        };
      });
    },
    setParam (value, name) {
      const tenants = value.filter((tenant) => (typeof tenant === 'string' || tenant instanceof String)).join(',');
      this.$store.commit(`${MODULE.USER_MANAGEMENT}/${COMMON.UPDATE_PARAM}`, {
        name: name,
        value: tenants,
      });
    },
    clearParams () {
      this.$store.commit(`${MODULE.USER_MANAGEMENT}/${COMMON.UPDATE_STATE}`, {
        name: 'params',
        value: {
          page: 0,
          size: 40,
          name: null,
          tenants: null,
        },
      });
      this.tenantsSelected = [];
      this.fetchUsersByTerm(this.filterText);
    },
    async getUsers () {
      this.$store.dispatch(`${MODULE.USER_MANAGEMENT}/${COMMON.FILTER_DATA}`, 'tenants');
    },
    fetchUsersByTerm (term) {
      this.$store.commit(`${MODULE.USER_MANAGEMENT}/${COMMON.UPDATE_PARAM}`, { name: 'name', value: term });
      this.$store.dispatch(`${MODULE.USER_MANAGEMENT}/${COMMON.FILTER_DATA}`, 'name');
    },
    fetchUsers: debounce(async function ({ target: { value } } = { target: '' }) {
      this.fetchUsersByTerm(value);
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.filter {
  &__search {
    margin: 30px 0px 10px;
  }
  &__combo {
    padding-top: 2px;
  }
  &__button {
    margin: 30px 5px 0;
    display: block;
    max-width: 90px;
  }
}
</style>
