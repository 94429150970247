export const TABLE_HEADERS = [
  {
    fieldName: 'userName',
    text: 'Username',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'fullName',
    text: 'Full Name',
    style: {
      width: '20%',
    },
  },
  {
    fieldName: 'email',
    text: 'Email',
    style: {
      width: '20%',
    },
  },
  {
    fieldName: 'tenants',
    text: 'Tenants',
    style: {
      width: '40%',
    },
  },
  {
    fieldName: 'actions',
    text: 'Actions',
    style: {
      width: '15%',
    },
  }
];
