<template>
  <mds-layout-grid>
    <mds-button
      variation="primary"
      @click="toggleCreateUserModal"
    >
      Create User
    </mds-button>
    <filter-section />
    <table-section />
    <user-creation-modal
      :visible="isCreateUserModalVisible"
      title="Create User"
      @update:visible="isCreateUserModalVisible = $event"
      @confirm="createUser"
    />
  </mds-layout-grid>
</template>

<script>
import { MdsButton } from '@mds/button';
import FilterSection from '@/components/user-management/list/FilterSection';
import TableSection from '@/components/user-management/list/table/TableSection.vue';
import UserCreationModal from '@/components/user-management/form/UserCreationModal.vue';
import { mapActions } from 'vuex';
import { MODULE } from '@/constants/store.constant';

export default {
  name: 'UserListPage',
  components: {
    MdsButton,
    FilterSection,
    TableSection,
    UserCreationModal,
  },
  data () {
    return {
      isCreateUserModalVisible: false,
    };
  },
  methods: {
    ...mapActions(MODULE.USER_MANAGEMENT, ['createUser']),
    toggleCreateUserModal () {
      this.isCreateUserModalVisible = !this.isCreateUserModalVisible;
    },
  },
};
</script>
