<template>
  <mds-row>
    <mds-col>
      <store-provider
        v-bind="$options.storeInfo"
        is-paged
      >
        <template v-slot="{ state: filterUsers }">
          <users-table :users="filterUsers" />
        </template>
      </store-provider>
    </mds-col>
  </mds-row>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider';
import UsersTable from '@/components/user-management/list/table/UsersTable';
import { MODULE, COMMON } from '@/constants/store.constant';

export default {
  name: 'TableSection',
  components: {
    StoreProvider,
    UsersTable,
  },
  storeInfo: {
    module: MODULE.USER_MANAGEMENT,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
};
</script>
